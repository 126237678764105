import React from 'react'
import { Container } from '@components'
import { AboutPage } from '@pages/components'
import { useWindowSize } from '@hooks'
import ArrowLeft from '@images/arrow-nav-left.svg'
import ArrowRight from '@images/arrow-nav-right.svg'
import { Button, Link } from '@elements'

interface BuyingGuideProps {}

const renderDesktopNav = () => {
  return (
    <div className="banner-guide-nav">
      <div className={'banner-guide__button'}>
        <Link to={'/about/condition/'}>
          <>
            <ArrowLeft /> Buying Guide{' '}
          </>
        </Link>
      </div>

      <div className={'banner-guide__button'}>
        <Link to={'/about/selling/'}>
          <>
            Selling Guide <ArrowRight />
          </>
        </Link>
      </div>
    </div>
  )
}

const renderMobileNav = () => {
  return (
    <div className="banner-guide-nav">
      <Button to={'/about/condition/'} type="roundBlack">
        Condition Guide
      </Button>

      <Button to={'/about/selling/'} type="roundBlack">
        Selling Guide
      </Button>
    </div>
  )
}

const BuyingGuide: React.FC<BuyingGuideProps> = () => {
  const { toRender: isMobileRender } = useWindowSize(['mobile', 'landscape'])
  return (
    <Container>
      <div className={'banner-guide'}>
        <div className="banner-guide__title"> Buying Guide</div>
        <div className="banner-guide__description">
          Search through the extensive TourDay catalog of golf equipment to find the item you want to buy.
        </div>

        {!isMobileRender && renderDesktopNav()}
        {isMobileRender && renderMobileNav()}
      </div>
      <AboutPage.BuyingGuide />
    </Container>
  )
}

export default BuyingGuide
